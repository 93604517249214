import axios from 'axios';

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'Newsletter',
    newsletter(email) {
        return axios.post(api_url + 'newsletter', {email:email}, { validateStatus: false });
    }
}
