<template>
  <div class="container newsletter">
    <div class="row newsletter-box">
      <div class="col-md-3 newsletter-icon col-md-offset-1">
        <h4>cadastre-se</h4>
        <h5>e receba novidades</h5>
      </div>
      <div class="col-md-6">
        <div class="form-group newsletter-input has-feedback">
          <span class="pull-right" style="position: absolute; right: 0">
            <a
              @click.prevent="newsletter_store"
              class="glyphicon btn glyphicon-chevron-right"
              href="#"
            ></a>
          </span>
          <input
            v-model="email"
            @keyup.enter="newsletter_store"
            type="text"
            class="form-control"
            placeholder="E-mail..."
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Newsletter from "@/api/Newsletter";
import Swal from "sweetalert2";

export default defineComponent({
  name: "Newsletter",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    newsletter_store() {
      let newsletter = Newsletter.newsletter(this.email);
      newsletter.then((request) => {
        if (request.data.errors) {
          Swal.fire("Oops...", request.data.errors.email[0], "error");
        } else if (request.data.id) {
          this.email = "";
          Swal.fire(
            "Obrigado!",
            "Agora você faz parte da nossa newsletter",
            "success"
          );
        }
      });
    },
  },
});
</script>

<style scoped>
</style>
